<template>
  <v-navigation-drawer
    v-model="model"
    class="IssueChatDrawer"
    width="540"
    right
    clipped
    fixed
  >
    <div
      :style="{ paddingTop: $vuetify.breakpoint.lgAndUp ? '64px' : '0' }"
    >
      <div
        class="IssueChatDrawer__toolbar"
      >
        <div
          class="IssueChatDrawer__toolbar-title"
          v-text="$t('issue.Messages')"
        />

        <v-btn
          class="ml-auto"
          tile
          text
          :width="48"
          :min-width="48"
          :height="48"
          color="#8492A6"
          @click="model = false"
        >
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </div>

      <IssueChat
        :issue-uuid="issueId"
        :messages-height="$vuetify.breakpoint.lgAndUp
          ? 'calc(100vh - 64px - 48px - 80px)'
          : 'calc(100vh - 48px - 80px)'"
        :style="$vuetify.breakpoint.lgAndUp
          ? { height: 'calc(100vh - 64px - 48px)' }
          : { height: 'calc(100vh - 48px)' }"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import * as R from 'ramda'

import IssueChat from '../components/IssueChat'

export default {
  name: 'IssueChatDrawer',

  components: {
    IssueChat,
  },

  props: {
    issueId: { type: String, required: true },
    value: { type: Boolean, default: true }, // isOpen
  },

  data() {
    return {
      model: this.value,
    }
  },

  computed: {
    closeRoute() {
      const queryLens = R.lensProp('query')
      return R.pipe(
        R.pick(['name', 'params', 'query', 'hash']),
        R.over(queryLens, R.omit(['chat'])),
      )(this.$route)
    },
  },

  watch: {
    // v-model
    value(value) {
      if (this.model !== value) this.model = !!value
    },
    model(value) {
      if (!value) this.close()
    },
  },

  methods: {
    close() {
      const { $router, closeRoute } = this
      return $router.push(closeRoute)
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueChatDrawer
  &__toolbar
    display: flex
    height: 48px
    align-items: center
    padding-left: 24px
    padding-right: 4px
    box-shadow: inset 0px -1px 0px #F0F1F3

    &-title
      font-size: 14px
      line-height: 24px
      color: #8B90A0
</style>
